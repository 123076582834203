'use client';

import React, { useState, useEffect } from 'react';
import Link from 'next/link';

interface Quote {
    content: string;
    author: string;
}

function LandingPage() {
    const [quote, setQuote] = useState<Quote | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        fetchDailyQuote();
    }, []);

    const fetchDailyQuote = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch('/api');
            if (!response.ok) {
                throw new Error('Failed to fetch quote');
            }
            const data = await response.json();
            setQuote({
                content: data.quote,
                author: data.author
            });
        } catch (err) {
            setError('Failed to load quote. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="w-full max-w-4xl mx-auto p-4">
            <h1 className="text-3xl font-bold text-center mb-8">Welcome to The Randoms Generator!</h1>

            <div className="bg-base-200 p-6 rounded-lg shadow-lg mb-8">
                <h2 className="text-2xl font-semibold mb-4">Random Quote of the Day</h2>
                {isLoading ? (
                    <p className="text-center">Loading quote...</p>
                ) : error ? (
                    <p className="text-error">{error}</p>
                ) : quote ? (
                    <div>
                        <p className="text-lg italic mb-2">&quot;{quote.content}&quot;</p>
                        <p className="text-right">- {quote.author || 'Unknown'}</p>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default LandingPage;